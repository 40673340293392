
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    customText: {
      type: String,
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
  setup() {
    const emailsValue = ref([])

    const inboxValue = ref('support@textmagic.com')
    const inboxOptions = ref([
      'support@textmagic.com',
      'admin@textmagic.com',
    ])

    return {
      emailsValue,
      inboxValue,
      inboxOptions,
    }
  },
})
